.title {
  color: var(--text, #d7b58d);
  font-family: 'Franklin Gothic Medium Cond', 'Arial';
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 400;
}

.title1 {
  color: var(--green-primary-black);
  font-family: 'Franklin Gothic Medium Cond', 'Arial';
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 400;
}

.title2 {
  color: var(--orange);
  padding: 30px;
  font-size: 2.0rem;
}

.sub-title {
  color: var(--white-color);
  font-size: 1.5rem;
  text-align: center;
}

.text {
  font-family: 'Franklin Gothic Book', 'Roboto', sans-serif;
  color: var(--text-light);
  font-size: 2.4rem;
  font-style: normal;
}

.text1 {
  color: var(--text); 
  font-size: 18px;
}

.bold {
  font-weight: bold;
}

.link {
  color: var(--text); 
  font-size: 18px;
  text-decoration: none;

}
