.mb-2{
  margin-bottom: 2px;
}

.mt-10{
  margin-top: 10px;
}

.mt-20{
  margin-top: 20px;
}

.mt-30{
  margin-top: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-30{
  margin-top: 30px;
}

.mb-10{
  margin-bottom: 10px;
}

.mb-30{
  margin-bottom: 30px;
}

.m-30 {
  margin: 30px;
}

.mr-30{
  margin-right: 30px;
}