.admin-page .buttons-container{
  display: flex;
  justify-content: center;
}


/* .admin-page button {
  background-color: var(--orange-accent);
  color: var(--text-light);
  padding: 8px 16px;
  border-radius: 5px;
  margin-left: 10px;
  font-family: Franklin, 'Arial Narrow', Arial, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border: none;
} */