.side-bar {
  border: 1px solid var(--grey-primary-light);
}

.side-bar-image-container {
  display: flex;
  margin: auto;
  padding: 20px 20px;
  /* justify-content: center; */
  /* align-items: center; */
  width: 70%;
}