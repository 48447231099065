.about-the-project {
  margin: 30px;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-container .card {
  font-size: 1rem;
  /* padding: 10px; */
  /* width: 223px;
  height: 330px; */
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.team-container .card-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94px;
  padding: 0px 0px 0px 0px;
  color: var(--text-light);
  font-size: 18px;
}

.team-container .card-position {
  color: var( --text);
  font-size: 15px;
}

/* .about-the-project-row-1 {
  display: flex;
  background-color: var(--green-primary);
} */

/* .about-the-project-row-1 .about-the-project-row-1-main {
  width: 70%;
  padding: 30px 15px 30px 30px;
} */

/* .about-the-project-row-1 .about-the-project-row-1-main-title {
  margin-bottom: 30px;
} */

/* .about-the-project-row-1 .about-row-1-main-text {
  font-size: 1.2rem;
} */
