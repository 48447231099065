.RegisterPage {
  background-color: var(--grey-primary-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FileInputContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.FileInputButtonContainer{
  width: 60%;
}

.PhotoContainer {
  border: 1px solid black;
}

.Form {
  background-color: var(--green-primary-black);
  margin: 20px;
  /* max-width: 700px; */
  padding: 20px;
  border-radius: 4px;
}

.Form label {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1.5rem;
  color: var(--text-light);
}

.Input {
  /* margin: 5px 20px; */
  margin-top: 5px;
  font-size: 20px;
  width: 100%;
  border-radius: 5px;
}

.ErrorMessage {
  font-size: 15px;
  color: yellow;
}

.HonoredList {
  margin: 0 20px;
}
