.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* display: grid;
  grid-template-rows: repeat(1, 30px);
  grid-template-columns: repeat(3, 1fr); */
}

/* .header-item-container {
  display: flex;
  padding: 30px 32px 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: 1px solid var(--grey-primary-light);
  height: 50px;
} */

/* .header-item-container:hover{
  outline: 4px solid var(--border-color-hover);
} */
