.contact-row-1 {
  margin: 30px;
}

.contact .contact-email-container {
  display: flex;
  align-items: center;
}

.contact .email-icon {
  width: 30px;
  height: 30px;
  color: var(--text-light);
}

.contact-row-1 .side-bar {
  /* width: 30%; */
}

.contact-row-1 .contact-row-1-main {
  /* width: 70%;
  padding: 30px 15px 30px 30px; */
}

.contact-row-1 .contact-row-1-main-title {
  /* margin-bottom: 30px; */
}

.contact-row-1 .contact-row-1-main-text {
  /* font-size: 1.2rem; */
}
