.honored {
  margin: 30px; 
  /* height: 100vh; */
}

.honored .loading {
  display: flex;
  justify-content: center;
}

.honored-row {
  display: flex;
  background-color: var(--green-primary);
  height: 100%;
}

.honored-row-1 .honored-row-1-main-text {
  font-size: 1.2rem;
}

.honored-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
}

.year-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 5px;

  color: var(--text-light);
  font-size: 1.6rem;
  border-radius: 5px;
  height: 30px;
  background-color: var(--green-primary-dark);
}

.year-button-clicked {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 5px;

  color: var(--text-light);
  font-size: 1.6rem;
  border-radius: 5px;
  height: 30px;
  background-color: var(--orange-accent);
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--green-primary-light);
  width: 70%;
  margin: auto;
}

.search-bar-container {
  /* width: 100%; */
  border: 1px solid var(--green-primary-light);
  padding: 30px;
}
