@import './commonstyles/typografy.css';
@import './commonstyles/spacing.css';
@import './commonstyles/components.css';

* {
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 68.5%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
