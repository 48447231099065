.page-base {
  /* background-color: yellow; */
  height: 100vh;
}


.page-base-row {
  display: flex;
  /* flex-wrap: wrap; */
  background-color: var(--green-primary);
  /* height: 83%; */
  min-height: 83%;
}

.page-base-row .side-bar-container {
  width: 28%;
  min-width: 300px;
  border-right: 1px solid var(--grey-primary-light);
}

.page-base-row .page-base-main {
  width: 70%;
  min-width: 300px;
  /* padding: 30px 30px 30px 50px; */
  /* background-color: red; */
}

.page-base-footer {
  /* height: 20%; */
}

@media screen and (max-width: 600px) {
  .page-base-row {
    display: block;
  }

  .page-base-row .side-bar-container {
    width: 100%;
    min-width: 300px;
  }

  .page-base-row .page-base-main {
    width: 100%;
    min-width: 300px;
    padding: 30px 15px 30px 30px;
  }
}
