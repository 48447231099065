.button {
  font-size: 1.5rem;
  color: var(--text-light);
  background: var(--orange);
  background-color: var(--orange-accent);
  padding: 3px 20px;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  align-self: center;
}

.button:hover {
  background: linear-gradient(to bottom, #6c7c7c 5%, #768d87 100%);
  background-color: #6c7c7c;
}

.button:active {
  position: relative;
  top: 1px;
}

.input {
  /* margin: 5px 20px; */
  margin-top: 5px;
  padding: 5px;
  font-size: 1.5rem;
  width: 100%;
  border-radius: 5px;
}