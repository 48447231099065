.footer {
  /* position: absolute;
  left: 0;
  bottom: 0; */
  background-color: var(--green-primary-dark);
}

.footer-row1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.footer-item-container {
  padding: 20px;
}

.footer-logo-container {
  width: 200px;
  padding: 10px;
}

.footer-logo-container img {
  width: 100%;
}

.footer-row2 {
  color: var(--green-primary-light);
  font-family: 'Franklin Gothic Medium Cond', 'Arial';
  font-size: 1.8rem;
  background-color: var(--green-primary-black);
  padding: 10px 0px 10px 15px;
}

.footer-row3 {
  display: flex;
  justify-content: center;
  color: var(--green-primary-light);
  font-family: 'Franklin Gothic Medium Cond', 'Arial';
  font-size: 1.3rem;
  background-color: var(--green-primary-black);
  padding: 10px 0px 10px 15px;
}

.footer .license {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}