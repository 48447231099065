.add-photos {
  background-color: var(--grey-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
}

.add-photos .main-image-container {
  /* width: 60%; */
  margin: 10px auto;
}

.add-photos .input-button {
  font-size: 1.5rem;
  color: var(--text-light);
  background: var(--orange);
  background-color: var(--orange-accent);
  padding: 3px 20px;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  align-self: center;
}

.photos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-primary-light);
}

.photo-container {
  margin: 20px;
  /* border: 1px solid var(--green-primary-black); */
  width: 300px;
} 

.photo-container img {
  width: 100%;
}

.add-photos .legend {
  padding: 5px;
  width: 96%;
  /* height: 40px; */
}

.add-photos .loading {
  text-align: center;
  display: block; 
}

.add-photos .images-gallery {
  background-color: var(--grey-primary-light);
  background-color: var(--text-light);
  border: solid 2px black;
  border-radius: 5px;
  margin: 20px;
  padding: 20px;
}

.add-photos .images-gallery-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.add-photos .images-gallery .photo-container {
  width: 200px;
}