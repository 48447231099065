.header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding-left: 10px;
  padding-right: 10px;
  /* padding: 30px 30px 30px 30px; */
  /* gap: 10px; */
  flex: 1 0 0;
  border: 1px solid var(--grey-primary-light);
  height: 80px;
}

.header-button a {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  text-decoration: none;
  color: var(--text-light);
}

.header-button:hover{
  outline: 4px solid var(--border-color-hover);
}

/* .color-change {
  color: var(--text-color-hover);
} */

.header-button a:hover {
  color: var(--text-color-hover);
}


/* .header-button a.active {
  background-color: var(--green-primary-dark);
  color: var(--orange);
} */

@media screen and (max-width: 900px) {
  .header-button {
    font-size: 2rem;
  }
}
