.links {
  margin: 30px;
  /* height: 100%vh; */
}

/* .about-row-1 .about-row-1-main {
  padding: 30px 15px 30px 30px;
}

.about-row-1 .about-row-1-main-title {
  margin-bottom: 30px;
}

.about-row-1 .about-row-1-main-text {
  font-size: 1.2rem;
} */
