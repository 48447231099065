.image-gallery-row-1 {
  display: flex;
  background-color: var(--green-primary);
  /* height: 100%; */
}

.images-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--green-primary-black);
  margin: 30px;
}

.image-container {
  background-color: red;
  border: 2px solid red;
}

.image-gallery-row-1 .carousel {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.photo {
  width: 300px; /* Adjust the width and height as needed */
  overflow: hidden; /* Hide any overflow outside the container */
  /* margin: 10px; Add some spacing between images */
}