@font-face {
  font-family: 'Franklin Gothic Medium Cond';
  src: url('../assets/fonts/OPTIFranklinGothic-Medium.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic Book';
  src: url('../assets/fonts/Franklin\ Gothic\ Book\ Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}


/* @font-face {
  font-family: 'YourFontName';
  src: url('../public/fonts/your-font.woff2') format('woff2'),
       url('../public/fonts/your-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */