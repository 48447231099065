:root {
  --green-primary: #2e4443;
  --green-primary-light: #6F7878;
  --green-primary-dark: #123836;
  --green-primary-black: #0E2B2A;
  --grey-primary-light: #6F7878;
  --grey-primary-semi-light: #3B5F5D;
  --text-light: #F4ECE2;
  --orange-accent: #D63C22;
  --orange: #F6BA45;
  --border-color-hover: #38ada7;
  --text-color-hover: #d1b18a;
  --text: #D7B58D;
}

.white {
  color: white;
}
