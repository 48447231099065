.card-with-date {
  font-size: 1.5rem;
  width: 223px;
  height: 330px;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.card-with-date .card-with-date-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 94px;
  padding: 10px 10px 10px 10px;
  color: var(--text-light);
  background-color: var(--green-primary-dark);
}

.card-with-date .card-with-date-photo-container {
  height: 250px;
  overflow: hidden; /* Hide any overflow outside the container */
  /* z-index: -4; */
  /* margin: 10px; Add some spacing between images */
}

.card-with-date .card-with-date-photo-container img{
  width: 100%; /* Make the image width fill the container */
  height: 100%; /* Make the image height fill the container */
  object-fit: cover; 
  position: relative;
  z-index: 0;
}

.card-with-date .card-name {
  display: flex;
  align-items: center;
  width: 80%;
}

.card-with-date .card-year {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #29998D;
  padding: 15px 15px;
  border-radius: 5px;
  height: 20px;
}