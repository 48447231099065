.card {
  /* border: 1px solid grey; */
  font-size: 1rem;
  /* padding: 10px; */
  width: 223px;
  height: 330px;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
}
 
.card-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--grey-primary-semi-light);
  /* background-color: red; */
  height: 94px;
  padding: 16px 16px 16px 16px;
  color: var(--text-light);
}

.card-photo-container {
  width: 223px; /* Adjust the width and height as needed */
  height: 240px;
  overflow: hidden; /* Hide any overflow outside the container */
  /* margin: 10px; Add some spacing between images */
}

.card-photo-container img{
  width: 100%; /* Make the image width fill the container */
  height: 100%; /* Make the image height fill the container */
  object-fit: cover; /* Crop and center the image within the container */
  /* filter: grayscale(100%); */
}

.card-name {
  /* width: 100%; */
  display: flex;
  align-items: center;
  width: 80%;
}

.card-year {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: var(--green-primary-dark);
  padding: 3px 5px;
  margin: 0px 1px 0px 5px;
  border-radius: 5px;
}