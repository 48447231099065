@import '../../commonstyles/fonts.css';

.search-bar-form{
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* justify-content: space-between; */
}

.search-bar-input {
  background-color: var(--green-primary-dark);
  color: var(--text-light);
  border: none;
  border-radius: 5px;
  width: 70%;
  min-width: 250px;
  height: 40px;
  font-size: 2rem;
  padding: 8px 16px;
}

.search-bar-input::placeholder {
  color: var(--green-primary-light);
}

.search-bar-button {
  background-color: var(--orange-accent);
  color: var(--text-light);
  padding: 8px 16px;
  border-radius: 5px;
  margin-left: 10px;
  font-family: Franklin, 'Arial Narrow', Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border: none;
}

@media screen and (max-width: 1000px) {
  .search-bar-button {
    margin-top: 10px;
  }
}
