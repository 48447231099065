.honored-page {
  margin: 30px;
}

.honored-page-main {
  display: flex;
  flex-direction: row;
  margin: 30px;
  /* background-color: red; */
}

.honored-page-main-col {
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.honor-details {
  width: 223px;
  font-size: 1.5rem;
  color: var(--text-light);
  background-color: var(--green-primary-dark);
  padding: 20px;
  border-radius: 5px;
}

.honored-bio {
  background-color: var(--green-primary-dark);
  padding: 30px;
  border-radius: 5px;
  margin: 0px 20px 0px 20px;
}

.search-bar-container {
  /* width: 100%; */
  border: 1px solid var(--green-primary-light);
  padding: 30px;
}

.icon {
  width: 20px;
  height: 20px;
}
