.show-honored {
  display: flex;
  flex-wrap: wrap;
}

table {
  border:1px solid #b3adad;
  border-collapse:collapse;
  padding:5px;

}
table th {
  border:1px solid #b3adad;
  padding:5px;
  background: #f0f0f0;
  color: #313030;
}
table td {
  border:1px solid #b3adad;
  text-align:center;
  padding:5px;
  background: #ffffff;
  color: #313030;
}