* {
  margin: 0;
  padding: 0;
}

.main-page {
  background-color: var(--green-primary);
  color: var(--text-light);
  /* grid-template-rows: 30vh min-content 40vw min-content min-content; 30% of view port heigth */
  min-width: 300px;
}

.container {
  width: 70%;
  margin: 0 auto;
}

.banner {
  padding-top: 30px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.header-container {
  margin: 0 auto;
  /* border: 1px solid var(--grey-primary-light); */
}

.main-text {
  border: 1px solid var(--grey-primary-light);
  padding: 30px;
  font-size: 2.0rem;
}

.search-bar-container {
  border: 1px solid var(--grey-primary-light);
}

.main-page-honored {
  border: 1px solid var(--grey-primary-light);
}

.footer {
  background-color: var(--green-primary-dark);
  border: 1px solid var(--grey-primary-light);
}

.text-main-page {
  color: var(--text-light);
  width: 70%;
}

.f {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .container {
    width: 100%;
  }
}